<template>
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">
                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{rowReg.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No Rekam Medis">{{rowReg.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No KTP">{{rowReg.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong
                                        class="font-weight-semibold">{{rowReg.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong
                                        class="font-weight-semibold">{{rowReg.ap_usia_with_ket || "-"}}<br />({{rowReg.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{rowReg.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{rowReg.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>

                        <!--
                        <div v-if="typeKajian=='kajian-dokter'||typeKajian=='kajian-dokter-terapi'||typeKajian=='kajian-gizi'" class="d-flex flex-column">
                            <a href="javascript:;" @click="viewKajianPerawat()" class="btn btn-outline-light">Lihat Kajian Perawat</a>

                            <a href="javascript:;" v-if="typeKajian=='kajian-dokter' && rowReg.kajianLatestDokter" @click="doOpenRiwayatDokter(rowReg.kajianLatestDokter)" class="btn btn-outline-light mt-1">Lihat Riwayat Rekam Medis</a>
                        </div>
                        
                        
                        <div v-else class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                        -->
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
                <i class="icon-arrow-up5 align-middle"></i>
                <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        <div class="content">
            <div class="card">
                <div class="card-header">
                    <div class="d-flex align-items-center">
                        <h5 class="card-title font-weight-semibold mr-auto">Dokumen Terkait Pasien</h5>
                    </div>
                </div>
                <table class="table table-bordered table-sm patient-table">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th>Nama Dokumen</th>
                            <th>Status</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (mTable||[])" :key="k">
                            <td>{{k+1}}</td>
                            <td>{{v.name}}</td>
                            <td>
                                <span v-if="v.status_terbit == 'Y'" class="badge badge-success">Terbit</span>
                                <span v-else-if="v.status_terbit == 'U'" class="badge badge-primary">Unduh Dokumen</span>
                                <span v-else class="badge badge-warning">Belum Terbit</span>
                            
                            </td>
                            <td>
                                <!--
                                <a @click="openModalterbit(v)" v-if="v.status_terbit != 'Y' && v.status_terbit != 'U'" href="javascript:;" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover title="Terbitkan Dokumen"><i class="icon-file-check"></i></a>
                                -->

                                <a :href="$parent.uploader(rowReg['aur_'+v.slug+'_file'])" target="_blank" v-if="v.type == 'dynamic'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover title="Unduh Dokumen"><i class="icon-download"></i></a>
                                
                                <a href="javascript:;" @click="generateFile(rowReg.aur_id,v.slug)" v-if="v.type == 'static'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover title="Unduh Dokumen"><i class="icon-download"></i></a>
                            </td>
                        </tr>       
                    </tbody>
                </table>
            </div>
        </div>

        <b-modal 
            v-model="modalTerbit"
            @ok.prevent="terbitKanSurat"
            size="sm"
            no-close-on-backdrop
            class="text-center"
            :title="'Konfirmasi Penerbitan Surat'" 
        >
            <div class="text-center mb-3">
            <div class="modal-shout-icon d-inline-block alpha-info text-info rounded-circle">
                <i class="icon-question4"></i>
            </div>
            <h4 class="font-weight-semibold">Terbitkan {{rowEdit.name}}?</h4>
            <p>Konfirmasi untuk menerbitkan dan mengunduh dokumen {{rowEdit.name}}</p>
            </div>
        </b-modal>
    </div>
</template>


<script>
    import $ from 'jquery'
    const _ = global._
    const moment = require('moment')
    import Gen from '@/libs/Gen.js'
    
    export default {
        props: {
            row: Object,
            mrValidation: Object,
            Config: Object,
            rowReg: Object,
            mKesadaran: Array,
            mTable: Array
        },
        data(){
            return {
                modalTerbit : false,
                rowEdit: {}
            }
        },
        methods: {
            terbitKanSurat(){
                let data = this.rowEdit
                data.aur_id = this.rowReg.aur_id
                data.type = "terbitkan-surat"
                this.$parent.loadingOverlay = true
                Gen.apiRest(
                    "/do/"+this.$parent.modulePage,
                    {data:data}, 
                    "POST"
                ).then(res=>{
                    this.$parent.loadingOverlay = false
                    this.modalTerbit = false
                    let resp = res.data
                    resp.statusType = 200
                    this.$parent.$swal({
                        title: resp.message,
                        icon: resp.status,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    })
                    this.$parent.apiGet()
                }).catch(err=>{
                    this.$parent.loadingOverlay = false
                    if (err) {
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title
                        err.message = err.response?.data?.message
                        err.messageError = err.message
                    }
                    this.$parent.doSetAlertForm(err)
                })
            },
            openModalterbit(row){
                this.modalTerbit = true
                this.rowEdit = row
            },
            initSticky() {
                const PageHeaderSticky = document.querySelector('.page-header-sticky')
                if (PageHeaderSticky) {
                    const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                    if ($(".page-header-sticky").length && $(".page-header-sticky-toggle").length) {
                        PageHeaderToggle.addEventListener('click', function (e) {
                            // e.preventDefault()
                            PageHeaderSticky.classList.toggle('page-header-sticky-open')
                            if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                                PageHeaderToggle.querySelector('span').innerText =
                                    'Sembunyikan Informasi Pasien'
                                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                            } else {
                                PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                            }
                        })
                    }
                }
            },
            generateFile(id,val){
                let data = {exptype: 'xlsx', type: "export", id: id}
                let self = this
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${val}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${val}-${moment().format("YYYY-MM-DD")}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },
        },   
        mounted() {
            setTimeout(() => {
                this.initSticky()
            }, 1000)
        },
    }
</script>